<template>
  <v-container class="ml-5 mr-5 my-5">
    <v-layout row wrap>
      nickname: {{nickname}}
      sex: {{sex}}
      yearMonth: {{yearMonth}}
      birthYear: {{birthYear}}
      birthMonth: {{birthMonth}}
      userId: {{userId}}
      <v-form name="register-form" ref="registerStudentForm" autocomplete='off' v-model="isFormVisiblyValid" lazy-validation>
        <v-stepper v-model="stepperStep" vertical flat>

          <!-- nickname -->
          <v-stepper-step
            :complete="stepperStep > 1"
            editable
            step="1">
            学员称呼
            <small>填写学员可以自行识别的称呼</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card class="mb-5" flat>
              <v-text-field
                label="学员称呼"
                name="nickname"
                v-model="nickname"
                :rules ="nicknameRules"
              ></v-text-field>
            </v-card>
            <v-btn color="primary"
              @click="stepperStep = 2"
              :disabled="!isFormVisiblyValid">
              下一步
            </v-btn>
            <v-btn text @click="cancel">取消</v-btn>
          </v-stepper-content>

          <!-- birth year and sex -->
          <v-stepper-step
            :complete="stepperStep > 2"
            editable
            step="2">
            出生年份与性别
            <small>将用于为学员匹配符合其兴趣水平的项目</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card class="mb-5" flat>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menu"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="yearMonth"
                  label="出生年月"
                  prepend-icon="event"
                  readonly
                  :rules="yearMonthRules"
                ></v-text-field>
                <v-date-picker
                  ref="picker"
                  v-model="yearMonth"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="2000-01-01"
                  @change="save"
                  type="month"
                  locale="zh-cn"
                ></v-date-picker>
              </v-menu>
              <v-radio-group
                v-model="sex"
                label="性别"
                name="sex"
                :rules="sexRules">
                <v-radio
                  :key="1"
                  :label='`女`'
                  :value='`F`'
                ></v-radio>
                <v-radio
                  :key="2"
                  :label='`男`'
                  :value='`M`'
                ></v-radio>
              </v-radio-group>
            </v-card>
            <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || '须阅读并同意才能继续']"
              label="我已阅读并同意协议"
              required
            ></v-checkbox>
            <br>
            <div class="error" v-html="error" />
            <br>
            <v-btn
              color="primary"
              :disabled="!isFormVisiblyValid"
              @click="submit({ nickname: nickname, sex: sex, birthYear: birthYear, birthMonth: birthMonth, userId: userId })">
              <v-progress-circular
                v-if="showWait"
                indeterminate
                color="white"
                :size="20"
                :width="2"
              ></v-progress-circular>
              <span v-if="showWait">&nbsp;</span>
              注册
            </v-btn>
            <v-btn text @click="cancel">取消</v-btn>
          </v-stepper-content>

          <!-- result -->
          <v-stepper-step :complete="stepperStep > 3" step="3">
            完成
          </v-stepper-step>

          <v-stepper-content step="3">
            {{ error }}
            <v-btn color="primary"
              v-if="!error"
              @click="$router.push('login')">
              前往登录
            </v-btn>
          </v-stepper-content>

        </v-stepper>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  components: {
  },
  computed: {
    birthYear: function () {
      var d = new Date(this.yearMonth)
      if (d.valueOf()) { // Valid date
        return d.getFullYear()
      } else return null
    },
    birthMonth: function () {
      var d = new Date(this.yearMonth)
      if (d.valueOf()) { // Valid date
        return d.getMonth() + 1
      } else return null
    }
  },
  data () {
    return {
      isFormVisiblyValid: true,
      router: '',
      nickname: '',
      nicknameRules: [
        v => !!v || '请填写学员可识别的称呼'
      ],
      sex: '',
      sexRules: [
        v => /^[M,F,U]{1,1}$/.test(v) || '请选择性别'
      ],
      yearMonth: null,
      yearMonthRules: [
        v => !!v || '需要选择出生年月'
      ],
      menu: false,
      checkbox: false,
      error: null,
      stepperStep: 1,
      userId: null,
      showWait: false
    }
  },
  watch: {
    menu (val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.userId = this.$store.state.route.params.userId
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    async submit (studentInfo) {
      if (this.$refs.registerStudentForm.validate() && studentInfo.userId) { // the form element is marked as ref="form" and can be refered here
        this.showWait = true
        try {
          console.log('in try, userId: ', studentInfo.userId)
          const response = await this.registerStudent(studentInfo)
          this.showWait = false
          if (response.studentId) {
            this.error = null
            console.log('student has been created! response.data:', response)
          } else {
            console.log('something went wrong in creating student')
          }
        } catch (error) {
          this.error = error.response.data.msg
          this.showWait = false
        }
        this.stepperStep = 3
      }
    },
    cancel () {
      this.$refs.registerStudentForm.reset()
      this.error = null
      this.stepperStep = 1
    },
    async registerStudent (studentInfo) {
      // studentInfo = {birthdayYear: '', birthdayMonth: '', nickname: '', sex: '', userId: ''}
      try {
        const response = await AuthenticationService.registerStudent(studentInfo)
        console.log('registerStudent was called! response.data:', response.data)
        return response.data
      } catch (err) {
        return err.response.data
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
